import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57')
];

export const server_loads = [];

export const dictionary = {
		"/": [~17],
		"/[locale]": [18,[2]],
		"/[locale]/(docs)/about": [19,[2,3]],
		"/[locale]/(main)/(without-head)/account": [50,[2,6,15]],
		"/[locale]/(main)/(without-head)/account/purchase": [51,[2,6,15]],
		"/[locale]/(main)/(without-head)/account/record": [52,[2,6,15]],
		"/[locale]/(main)/(with-head-menubar)/album": [46,[2,6,14]],
		"/[locale]/(main)/(full-height)/album/[id]": [23,[2,6,8]],
		"/[locale]/(main)/(full-height)/chat/comments/feeds": [24,[2,6,8,9]],
		"/[locale]/(main)/(full-height)/chat/comments/videos": [25,[2,6,8,9]],
		"/[locale]/(main)/(full-height)/chat/fans/[userId]": [26,[2,6,8]],
		"/[locale]/(main)/(full-height)/chat/favorites": [27,[2,6,8]],
		"/[locale]/(main)/(full-height)/chat/follow": [28,[2,6,8]],
		"/[locale]/(main)/(full-height)/chat/likes/feeds": [29,[2,6,8,10]],
		"/[locale]/(main)/(full-height)/chat/likes/videos": [30,[2,6,8,10]],
		"/[locale]/(main)/(full-height)/chat/system": [31,[2,6,8]],
		"/[locale]/components": [57,[2]],
		"/[locale]/(main)/(full-height)/conversation/[conversationId]": [32,[2,6,8]],
		"/[locale]/(main)/(full-height)/debug": [33,[2,6,8]],
		"/[locale]/(main)/(full-height)/feed/[id]": [34,[2,6,8]],
		"/[locale]/(main)/(with-head-menubar)/following": [47,[2,6,14]],
		"/[locale]/(main)/(with-head-menubar)/home": [48,[2,6,14]],
		"/[locale]/(main)/(full-height)/member-centre": [35,[2,6,8,11]],
		"/[locale]/(main)/(full-height)/member-centre/vip": [36,[2,6,8,11]],
		"/[locale]/(main)/(full-height)/member-centre/wallet": [37,[2,6,8,11]],
		"/[locale]/(main)/(without-head)/model/all": [53,[2,6,15,16]],
		"/[locale]/(main)/(without-head)/model/information": [54,[2,6,15,16]],
		"/[locale]/(main)/(full-height)/model/[userId]/moment": [38,[2,6,8,12]],
		"/[locale]/(main)/(full-height)/model/[userId]/work": [39,[2,6,8,12]],
		"/[locale]/(main)/(with-head-menubar)/moment": [49,[2,6,14]],
		"/[locale]/(main)/(without-head)/payment-method": [55,[2,6,15]],
		"/[locale]/(docs)/privacy-policy": [20,[2,4]],
		"/[locale]/(main)/(full-height)/search": [40,[2,6,8]],
		"/[locale]/(main)/(full-height)/service-provisions": [41,[2,6,8]],
		"/[locale]/(main)/(without-head)/settings": [56,[2,6,15]],
		"/[locale]/(main)/(full-height)/share-promotion": [42,[2,6,8]],
		"/[locale]/(main)/(full-height)/single-video/[id]": [43,[2,6,8]],
		"/[locale]/(main)/(full-height)/tags/[id]/moment": [44,[2,6,8,13]],
		"/[locale]/(main)/(full-height)/tags/[id]/recommended": [45,[2,6,8,13]],
		"/[locale]/(docs)/terms-of-service": [21,[2,5]],
		"/[locale]/(main)/(content-no-scroll)/vlog": [22,[2,6,7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';